<template>
  <b-modal
    @shown="shown"
    :hide-header="true"
    :hide-footer="true"
    size="lg"
    content-class="modal-blue"
    id="modal-news">
    <div class="row modal-message-content">
      <div v-if="!loading && news" class="col mb-5 pb-5">
        <h4 class="mb-5 text-white">{{ helpers.getLocalizedField(news, 'title') }}</h4>
        <div v-if="news.image"><img :src="news.image" :alt="helpers.getLocalizedField(news, 'title')" /></div>
        <p class="my-4 text-white" v-html="helpers.getLocalizedField(news, 'text')"></p>
      </div>
      <div v-else-if="loading" class="col mb-5 pb-5">
        <sk-list height="0.02" :items-count="4"></sk-list>
      </div>
    </div>
    <div class="row">
      <div class="col mt-0 pt-3 mt-sm-5 pt-sm-0 mb-3">
        <button type="button" class="btn btn-light-bold" @click="hideModal()">{{ $t('news.close') }}</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import NewsResource from '../../api/newsResource';
  import SkList from '../../components/skeletons/sk-list';

  const newsResource = new NewsResource();

  export default {
    name: 'modal-news',

    components: {SkList},

    props: ['newsId'],

    data() {
      return {
        news: null,
        loading: false
      }
    },

    methods: {
      hideModal() {
        this.$bvModal.hide('modal-news');
      },

      shown() {
        this.loadNewsData();
      },

      loadNewsData() {
        this.loading = true;
        newsResource.news(this.newsId)
        .then(response => {
          this.news = response.data.data;
        })
        .catch(error => {
          this.helpers.notifyErrorMessage(this.$t('errors.general'));
        })
        .finally(() => {
          this.loading = false;
        })
      }
    }
  };
</script>
