<template>
  <!-- SIDEBAR start -->
  <!-- sidebar is visible only down < 576px width device -->
  <section>
    <div class="row">
      <div class="col-12 ">
        <div class="sidebar sidebar_sm mt-4 ">
          <sk-navbar-sidebar v-if="!sidebarNavItems || sidebarNavItems.length === 0"></sk-navbar-sidebar>
          <ul v-else class="nav">
            <li v-if="user.currentExam==0" class="nav-item" v-for="navItem of sidebarNavItems">
              <router-link v-if="navItem.is_oldug_redirect == 0"
                           :id="navItem.htmlID ? navItem.htmlID : ''"
                           class="nav-link"
                           :to="navItem.link">
                <img :src="navItem.icon" alt="" class="img-fluid">
                {{ helpers.getLocalizedField(navItem, 'name', 'name_eng') }}
                <span v-if="unreadConversations > 0 && navItem.htmlID === 'conversations'" class="ml-4 badge-danger badge-pill">{{ unreadConversations }}</span>
              </router-link>
              <a v-else
                 :id="navItem.htmlID ? navItem.htmlID : ''"
                 class="nav-link"
                 target="_blank" :href="'https://old.my.ug.edu.ge/login.php?redirect=' + navItem.link + '&token=' + token">
                <img :src="navItem.icon" alt="" class="img-fluid">
                {{ helpers.getLocalizedField(navItem, 'name', 'name_eng') }}
                <span v-if="unreadConversations > 0 && navItem.htmlID === 'conversations'" class="ml-4 badge-danger badge-pill">{{ unreadConversations }} </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- SIDEBAR end -->

</template>

<script>
  import { mapGetters } from 'vuex';
  import skSidebar from '../../components/skeletons/sk-sidebar';
  import skNavbarSidebar from '../../components/skeletons/sk-navbar-sidebar';

  export default {
    name: 'sidebar-sm',

    components: {
      skSidebar, skNavbarSidebar,
    },

    computed: {
      ...mapGetters('navbar', [
        'sidebarNavItems',
      ]),
      ...mapGetters('auth', [
        'studentPicture',
        'user',
        'token'
      ]),
      ...mapGetters('conversation', [
        'unreadConversations',
      ]),
    },
  };
</script>
