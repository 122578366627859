<template>
  <div>
    <b-modal
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="pay-online-modal"
      size="md">
      <!-- Modal content -->
      <div class="row text-white content text-left">
        <pay-online-form :pay-for-id="payForId" :type-id="typeId" :price="price" :auto-redirect="autoRedirect" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import PayOnlineForm from './pay-online-form';
export default {
  name: 'pay-online-modal',

  props: ['typeId', 'price', 'payForId', 'autoRedirect'],

  components: { PayOnlineForm },

  mounted() {
    this.showModal();
  },

  methods: {
    close() {
      this.$bvModal.hide('pay-online-modal');
    },
  }
};
</script>
<style scoped>
  .content {
    font-size: 1.6rem;
  }
</style>
