<template>
  <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
    <sidebar-sm></sidebar-sm>
    <breadcrumb :items="pageData.breadcrumb"></breadcrumb>
    <!-- CALENDAR -->
    <section class="short-term-section p-5">
      <div v-for="subject of subjects" :key="subject.subjectID" class="bg-light b-30 p-5 shadow-1 row">
        <div class="col-12 col-md-3">
          <img class="course-img" :src="subject.image_path"/>
        </div>
        <div class="col-12 col-md-9">
          <div class="course-details">
            <div class="course-name">{{ subject.subject }}</div>
            <div class="course-description" v-html="subject.description"></div>
            <div class="course-price my-2">
              {{ $t('short-term.price') }}: {{ subject.price }}₾
            </div>
            <div class="course-actions">
              <button v-if="!subject.is_paid" @click="handlePaymentClick(subject)" class="btn btn-primary-red">{{ $t('short-term.buy') }}</button>
              <button v-if="subject.is_paid" @click="handleShowCourse(subject)" class="btn btn-primary-red">{{ $t('short-term.show-course') }}</button>
            </div>
          </div>
        </div>
      </div>
      <pay-online-modal type-id="3" :price="chosenSubject ? chosenSubject.price : 0" :pay-for-id="chosenSubject ? chosenSubject.subjectID : ''" :auto-redirect="true" />
      
    </section>
  </main>
</template>

<script>
import breadcrumb from '../page/breadcrumb';
import sidebar from '../navbar/sidebar';
import navHeader from '../navbar/nav-header';
import pageTitle from '../page/page-title';
import sidebarSm from '../navbar/sidebar-sm';
import PayOnlineModal from '../pay-online-modal';
import ShortTermCourseResource from '../../api/shortTermCourseResource';

const resource = new ShortTermCourseResource()

export default {
  name: 'short-term',

  components: { PayOnlineModal, breadcrumb, sidebar, navHeader, pageTitle, sidebarSm },

  computed: {
    pageData() {
      return {
        title: this.$t('short-term.title'),
        breadcrumb: [{
          icon: '',
          title: this.$t('short-term.title'),
          link: '/',
          isActive: true,
        }],
      };
    },
  },

  data() {
    return {
      chosenSubject: null,
      subjects: []
    }
  },

  mounted() {
    this.loadShortTermSubjects()
     
  },

  methods: {
    loadShortTermSubjects() {
      resource.subjects()
        .then(resp => {
          this.subjects = resp.data.data
        })
        .catch(err => {
          this.helpers.notifyErrorMessage(err)
        })
    },

    handlePaymentClick(subject) {
      this.chosenSubject = subject;
      
      this.$bvModal.show('pay-online-modal');
    },

    handleShowCourse(subject) {
      this.$router.push('/courses/' + subject.subjectID)
    }
  }
};
</script>

<style scoped>
  .course-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .course-details > div {
  }

  .short-term-section {
    font-size: 16px;
  }

  .course-img {
    max-width: 100%;
    object-fit: cover;
  }

  .course-name {
    font-size: 18px;
    font-weight: bold;
  }

  .course-price {
    font-size: 18px;
    color: #d92828
  }
</style>
