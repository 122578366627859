<template>
  <div class="event-card">
    <div>
      <p>{{ helpers.getLocalizedField(message, 'title') }}</p>
      <button @click="showModal" class="btn btn-primary-red">{{ $t('home.modal-messages.read-more') }}</button>
    </div>
    <b-modal
      @shown="shown"
      :hide-footer="true"
      :hide-header="true"
      :no-close-on-esc="mustAgree"
      :no-close-on-backdrop="mustAgree"
      :hide-header-close="mustAgree"
      size="lg"
      content-class="modal-blue"
      :id="'modal-' + message.modal_messageID">
      <div class="row modal-message-content">
        <div class="col mb-5 pb-5 ">
          <h4 class="mb-5 text-white">{{ helpers.getLocalizedField(message, 'title') }}</h4>
          <p class="my-4 text-white" v-html="helpers.getLocalizedField(message, 'text')"></p>
        </div>
      </div>
      <div v-if="mustAgree" class="row">
        <div class="col mt-0 pt-3 mt-sm-5 pt-sm-5 mb-3">
          <button type="button" class="btn btn-light-bold" @click="onAgreeClick">{{ $t('home.modal-messages.agree') }}</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import moment from 'moment';
  import { mapGetters } from 'vuex';

  export default {
    name: 'modal-message',

    props: ['message'],

    computed: {
      mustAgree() {
        return this.message.recipients[0].seen == 0 && this.message.agree_button == 1
      },

      ...mapGetters({
        mustUploadDocs: 'auth/mustUploadDocs'
      })
    },

    mounted() {
      const now = moment();
      const from = moment(this.message.date_from);
      const to = moment(this.message.date_to);

      if(now <= to && now >= from && this.message.recipients[0].seen == 0 && !this.mustUploadDocs) {
        this.showModal();
      }
    },

    methods: {
      showModal() {
        this.$bvModal.show('modal-' + this.message.modal_messageID);
      },

      shown() {
        if(!parseInt(this.message.agree_button)) {
          this.seen();
        }
      },

      onAgreeClick() {
        this.seen()
        .then(() => {
          this.$bvModal.hide('modal-' + this.message.modal_messageID);
        })
      },

      seen() {
        if(this.message.recipients[0].seen == 0) {
          return this.$store.dispatch('popup_messages/seen', this.message.modal_messageID);
        }

        return new Promise(resolve => {});
      }
    }
  };
</script>

<style scoped>

</style>
