import axios from 'axios';

export default class ShortTermCourseResource {
  subjects() {
    return axios.get('/api/short-term-courses')
  }

  progress(subjectId) {
    return axios.get('/api/short-term-courses/' + subjectId + '/progress')
  }

  quiz(subjectId) {
    return axios.get('/api/short-term-courses/'+subjectId+'/quiz')
  }

  submitAnswers(subjectId, answers) {
    return axios.post('/api/short-term-courses/'+subjectId+'/quiz', { answers })
  }

  seen(subjectId, videoId) {
    return axios.post('/api/short-term-courses/'+ subjectId +'/video/' + videoId)
  }
}
