<template>
  <div class="event-card">
    <div>
      <p>{{ $t('distance-finals.modal-title') }}</p>
      <button @click="showModal" class="btn btn-primary-red">{{ $t('home.modal-messages.read-more') }}</button>
    </div>
    <b-modal
      :hide-footer="true"
      :hide-header="true"
      :hide-header-close="true"
      size="lg"
      content-class="modal-blue"
      id="distance-final-modal">
      <div class="row modal-text">
        <div class="col mb-5 pb-5">
          <h4 class="mb-5 modal-title">{{ $t('distance-finals.modal-title') }}</h4>
          <div class="my-4 text-left">
            <div v-html="$t('distance-finals.modal-description1')"></div>
            <div class="modal-items my-4">
              <b-form-group :label="$t('distance-finals.choose-reason')">
                <b-form-radio class="mb-4" v-model="reason" name="some-radios" value="riskGroup">
                  1. {{ $t('distance-finals.risk-group-title') }}
                </b-form-radio>
                <b-form-radio class="mb-2" v-model="reason" name="some-radios" value="riskGroupRelative">
                  2. {{ $t('distance-finals.risk-group-relative-title') }}
                </b-form-radio>
              </b-form-group>
              <div class="text-center mb-2" style="color: #ee4444">
                {{ $t('distance-finals.deadline') }}
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-light-bold" @click="openModal">{{ $t('distance-finals.register') }}</button>
              </div>
            </div>
            <div v-html="$t('distance-finals.modal-description2')"></div>
            <!--            <div class="text-left mt-4">-->
            <!--              <b-form-group label="3) Please upload the scanned version of your valid passport.">-->
            <!--                <b-form-file-->
            <!--                  class="col-12 col-md-6"-->
            <!--                  v-model="passportFile"-->
            <!--                  placeholder="Choose a file or drop it here..."-->
            <!--                  drop-placeholder="Drop file here..."-->
            <!--                ></b-form-file>-->
            <!--              </b-form-group>-->
            <!--            </div>-->
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col mt-0 pt-3 mt-sm-5 pt-sm-5 mb-3">
          <button type="button" class="btn btn-light-bold" @click="close">{{ $t('distance-finals.close') }}</button>
        </div>
      </div>
    </b-modal>
    <risk-group-modal @application-submitted="handleSubmit"></risk-group-modal>
    <risk-group-relative-modal @application-submitted="handleSubmit"></risk-group-relative-modal>
  </div>
</template>

<script>
import RiskGroupRelativeModal from './risk-group-relative-modal';
import RiskGroupModal from './risk-group-modal';

export default {
  name: 'distance-final-modal',

  components: { RiskGroupModal, RiskGroupRelativeModal },

  data() {
    return {
      reason: ''
    }
  },

  methods:{
    handleSubmit() {
      setTimeout(() => {
        this.close();
      }, 200);
    },

    openModal() {
      if(this.reason === 'riskGroup')
        this.showRiskGroupModal();

      if(this.reason === 'riskGroupRelative')
        this.showRiskGroupRelativeModal();
    },

    showRiskGroupModal() {
      this.$bvModal.show('risk-group-modal');
      this.close();
    },

    showRiskGroupRelativeModal() {
      this.$bvModal.show('risk-group-relative-modal');
      this.close();
    },

    showModal() {
      this.$bvModal.show('distance-final-modal');
    },

    close() {
      this.$bvModal.hide('distance-final-modal');
    }
  }
};
</script>

<style scoped>
  .modal-text {
    font-size: 16px;
    padding: 0 60px;
  }

  .modal-items {
    border: 1px solid #fff;
    padding: 24px;
    margin: 0 -46px;
  }

  .modal-items li {
    margin-bottom: 14px;
  }
</style>
