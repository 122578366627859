<template>
  <div>
    <full-calendar id="myugcalendar" :options="calendarOptions"  />
    <modal-news :news-id="chosenNewsId"></modal-news>
  </div>
</template>

<script>
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import ModalNews from './modal-news';
  import moment from 'moment';
  import NewsResource from '../../api/newsResource';

  const newsResource = new NewsResource();

  export default {
    name: 'calendar',

    components: {
      ModalNews,
      FullCalendar,
    },

    data() {
      return {
        events: [],
        dateRanges: {
          from: '',
          to: ''
        },
        chosenNewsId: null,
        calendarOptions: this.getCalendarOptions(),

      }
    },

    mounted() {
      setTimeout(() => {
        this.loadEvents(this.dateRanges)
      }, 1000)



      document.getElementsByClassName('fc-prev-button')[0].addEventListener('click',()=>{


        let month=document.getElementsByClassName('fc-toolbar-title')[0].innerHTML.split(' ')[0]

        if(month=='October'){

          document.getElementsByClassName('fc-prev-button')[0].disabled=true

        } 

      })


      
      document.getElementsByClassName('fc-next-button')[0].addEventListener('click',()=>{


let month=document.getElementsByClassName('fc-toolbar-title')[0].innerHTML.split(' ')[0]

if(month!='October'){

  document.getElementsByClassName('fc-prev-button')[0].disabled=false

} 

})








    },

    methods: {

  


      loadEvents(dateRanges) {
        this.loading = true;
        // newsResource.calendarEvents(dateRanges).then(response => {
        //   this.events = response.data.data;
        //   this.calendarOptions = this.getCalendarOptions();
        // })
        //   .finally(() => {
        //     this.loading = false;
        //   });
      },

      getCalendarOptions() {
        return {
          plugins: [ dayGridPlugin, interactionPlugin ],
          initialView: 'dayGridMonth',
          events: this.events,
          
          datesSet: function(event) {
            const dateRanges = {
              from: moment(event.start).format('YYYY-MM-DD'),
              to: moment(event.end).format('YYYY-MM-DD')
            }

            // newsResource.calendarEvents(dateRanges).then(response => {
            //   this.events = response.data.data;
            // })

          
          },
          eventClick: (info) => {
            this.chosenNewsId = info.event.id;
            this.$bvModal.show('modal-news')
          }
        }
      }
    }
  };
</script>

<style scoped>

</style>
