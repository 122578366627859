<template>
    <b-modal
      :hide-footer="true"
      :hide-header="true"
      :hide-header-close="true"
      size="lg"
      content-class="modal-blue"
      v-model="isOpen"
      @shown="onShow"
      @hidden="showParentModal"
      id="risk-group-modal">
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2 mb-5 pb-5">
          <div class="my-4 modal-text">
            <div class="text-left mt-4">
              <b-form-group :label="$t('distance-finals.reason1')">
                <b-textarea
                  rows="7"
                  class="col-12"
                  v-model="text"
                ></b-textarea>
              </b-form-group>
              <b-form-group :label="$t('distance-finals.choose-file')">
                <b-form-file
                  class="col-12"
                  v-model="file"
                ></b-form-file>
              </b-form-group>
            </div>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col mt-0 pt-3 mt-sm-5 pt-sm-5 mb-3">
          <button type="button" class="btn btn-light-bold" @click="onSubmitClick">{{ $t('distance-finals.submit') }}</button>
          <button type="button" class="btn btn-light-bold" @click="close">{{ $t('distance-finals.close') }}</button>
        </div>
      </div>
    </b-modal>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import RiskGroupRelativeModal from './risk-group-relative-modal';
export default {
  name: 'risk-group-modal',
  // components: { Multiselect },

  data() {
    return {
      isOpen: false,
      text: '',
      file: null,
    }
  },

  mounted() {
  },

  methods: {
    onShow() {
      this.text = '';
      this.file = null;
    },

    close() {
      this.$bvModal.hide('risk-group-modal');
    },

    showParentModal() {
      this.$bvModal.show('distance-final-modal');
    },

    hideParentModal() {
      this.$bvModal.hide('distance-final-modal');
    },

    onSubmitClick() {
      const formData = new FormData();

      let errors = [];
      if(!this.text){
        errors.push(this.$t('distance-finals.required-reason'));
      }
      if(!this.file){
        errors.push(this.$t('distance-finals.required-file'));
      }

      if(errors.length > 0) {
        this.helpers.notifyWarningMessage(errors.join('<br/><br/>'), '', '', true)
        return;
      }

      formData.append('text', this.text);
      formData.append('doc', this.file);

      axios.post('/api/distance-exams/risk-group', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          this.helpers.notifySuccessMessage(this.$t('distance-finals.confirmation'));
          this.$bvModal.hide('risk-group-modal');
          this.$emit('application-submitted');
        })
        .catch(error => {
          this.helpers.notifyWarningMessage(error, '', '', true)
        })
    }
  }
};
</script>

<style scoped>
  .modal-text {
    font-size: 16px;
  }
</style>
