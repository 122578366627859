<template>
  <div class="w-100">
    <div class="form-group">
      <label for="type">{{$t('financial-info.pay-type')}}</label>
      <multiselect
        id="type"
        v-model="type"
        :options="types"
        track-by="value"
        :label="locale === 'ka' ? 'types' : 'typeseng'"
        :placeholder="$t('financial-info.choose-type')"
      ></multiselect>
    </div>
    <div class="form-group">
      <label for="amount">{{$t('financial-info.amount')}}</label>
      <input id="amount" type="number" v-model="amount" class="form-control" />
    </div>
    <div class="form-group text-center">
      <button @click="handlePayClick" id="buy" class="btn btn-primary-red">{{ $t('financial-info.pay') }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FinancialInfoResource from '../api/financialInfoResource';
import Multiselect from 'vue-multiselect';

const resource = new FinancialInfoResource();

export default {
  name: 'pay-online-form',

  components: { Multiselect },

  props: ['typeId', 'price', 'payForId', 'autoRedirect'],

  computed: {
    ...mapGetters({
      locale: 'language/locale'
    }),
  },

  data() {
    return {
      type: '',
      amount: '',
      types: [],
      link: '',
      loading: false,
    }
  },

  mounted() {
    this.loadTypes();
  },

  methods: {
    handlePayClick () {
      resource.getPaymentGatewayLink(this.amount, this.type.value, this.payForId).then(response => {
        this.link = response.data.data.link;

        if(this.link.includes('https://ecommerce.ufc.ge/ecomm2/ClientHandler'))
          window.location.href = this.link;
        else
          this.helpers.notifyErrorMessage(this.$t('errors.general'))
      })
        .catch(err => {
          this.helpers.notifyErrorMessage(err)
        })
    },

    loadTypes() {
      resource.debitTypes().then(response => {
        this.types = response.data.data;

        if (this.typeId) this.type = this.types.find(o => o.debt_typeID == this.typeId)

        if (this.price) this.amount = this.price

        if (this.autoRedirect) {
          const btnBuy = document.getElementById('buy');
          btnBuy.click();
        }
      })
    },
  }
};
</script>
<style scoped>
  @import "~vue-multiselect/dist/vue-multiselect.min.css";
  .content {
    font-size: 1.6rem;
  }
</style>
