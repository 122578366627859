<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar></sidebar>
      <nav-header></nav-header>
      
      <template v-if="user.studyLevel === 13">
        <short-term />
      </template>
      

      <!-- MAIN CONTENT start -->
      <main v-else role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
        <sidebar-sm></sidebar-sm>
        <breadcrumb :items="pageData.breadcrumb"></breadcrumb>
   
        <p class="f-p2 text-center" style="font-size: 20px;color:red">{{ helpers.getLocalizedField(user, 'studentStatus') }}</p>


        <div class="mx-auto d-flex align-items-center justify-content-center" v-if="user.missedDocuments" style="background-color:#cb2026; width: 70%; min-height: 80px; border-radius: 10px;">
          <p v-for="missedDocument of user.missedDocuments" class="text-white" style="font-size: 15px;">

            <span v-if="$i18n.locale=='ka'">მოგესალმებით,
            გთხოვთ,სასწავლო ოფისში დაუყოვნებლივ წარმოადგინოთ  {{ helpers.getLocalizedField(missedDocument, 'document_name')  }}, 
             {{ missedDocument.last_date.slice(0,10) }}-მდე</span>

            
             <span v-if="$i18n.locale=='en'">
              
              Greetings,
              Please, present the {{ helpers.getLocalizedField(missedDocument, 'document_name')  }} urgently till {{ missedDocument.last_date.slice(0,10) }}, at the study office.
 
              </span>
          
          </p>

        </div>



        <!-- CALENDAR -->
        <section class="calendar-section pb-4">
          <div class="event-cards-wrapper">
            <div class="event-card-slider">
              <div class="slides">
                <upload-docs-modal v-if="mustUploadDocs"></upload-docs-modal>
<!--                <distance-final-modal v-if="locale === 'ka'"></distance-final-modal>-->
                <modal-message :key="message.modal_messageID" v-for="message of messages" :message="message"></modal-message>
              </div>
            </div>
          </div>
          <div class="calendar-wrapper">
            <calendar></calendar>
          </div>
        </section>
      </main>
      <!-- MAIN CONTENT end -->
    </div>
  </div>
</template>

<script>
import sidebar from '../components/navbar/sidebar';
import breadcrumb from '../components/page/breadcrumb';
import sidebarSm from '../components/navbar/sidebar-sm';
import navHeader from '../components/navbar/nav-header';
import pageTitle from '../components/page/page-title';
import { mapGetters } from 'vuex';
import ModalMessage from '../components/home/modal-message';
import UploadDocsModal from '../components/home/upload-docs-modal';
import Calendar from '../components/home/calendar';
import DistanceFinalModal from '../components/distance-finals/distance-final-modal';
import ShortTerm from '../components/short-term/short-term'

export default {
  name: 'Home',
  components: { DistanceFinalModal, ShortTerm, Calendar, breadcrumb, ModalMessage, UploadDocsModal, sidebar, navHeader, pageTitle, sidebarSm },
  computed: {
    pageData() {
      return {
        title: this.$t('home.title'),
        breadcrumb: [{
          icon: '',
          title: this.$t('home.title'),
          link: '/',
          isActive: true,
        }],
      };
    },

    ...mapGetters({
      messages: 'popup_messages/messages',
      mustUploadDocs: 'auth/mustUploadDocs',
      user: 'auth/user',
      locale: 'language/locale',
    }),
  },

  mounted() {
    this.$store.dispatch('popup_messages/loadMessages');

    
  }
};
</script>

<style scoped>

</style>
