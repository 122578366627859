import axios from 'axios';

export default class NewsResource {
  calendarEvents(dateRange = { from: '', to: '' }) {
    //return axios.get('/api/news/events?date_from='+dateRange.from+'&date_to='+dateRange.to);
  }

  news(newsId) {
    return axios.get('/api/news/'+newsId);
  }
}
