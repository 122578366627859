<template>
  <div class="event-card">
    <div>
      <p>Dear International Students</p>
      <button @click="showModal" class="btn btn-primary-red">{{ $t('home.modal-messages.read-more') }}</button>
    </div>
    <b-modal
      :hide-footer="true"
      :hide-header="true"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      size="lg"
      content-class="modal-blue"
      v-model="isOpen"
      id="upload-docs-modal">
      <div class="row">
        <div class="col mb-5 pb-5">
          <h4 class="mb-5 modal-title">Dear International Students</h4>
          <div class="my-4 font-16">
            <div>
              We would love to inform you that recently the Parliament of Georgia has made significant changes in the law about “The legal status of Aliens and Stateless Persons.”
              <br />
              According to the new changes, those students who already have valid Georgian residency cards can renew their residency permits only after applying for the new residency permit with 40 days of legal stay left for them. The extensions will not be possible anymore.
              <br />
              Students who are abroad and do not have the residency permits should return to Georgia with Visas.
              <br />
              The University of Georgia would love to help you regarding the Temporary Residency Card application process and the Visa application process.
              <br />
              To successfully meet all the new requirements of the law, we would love to ask you to provide specific information regarding your residency Status.
            </div>
            <div class="text-left">
              <b-form-group label="1). Do you have a valid Georgian residency card (so called Georgian ID card)?">
                <b-form-radio v-model="hasGeorgianResidencyCard" :value="true">
                  Yes
                </b-form-radio>
                <b-form-radio v-model="hasGeorgianResidencyCard" :value="false">
                  No
                </b-form-radio>
              </b-form-group>
            </div>
            <div class="text-left" v-if="hasGeorgianResidencyCard">
              <b-form-group label="Please upload the scanned version of your Georgian residency card.">
                <b-form-file
                  class="col-12 col-md-6"
                  v-model="IDCardFile"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                ></b-form-file>
              </b-form-group>
            </div>
            <div class="text-left" v-else-if="hasGeorgianResidencyCard === false">
              <b-form-group label="Please explain the reason in the comment.">
                <b-form-textarea
                  id="textarea"
                  v-model="comment"
                  placeholder=""
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div class="text-left mt-4">
              <b-form-group label="2) Please indicate your current location: ">
                <b-form-radio v-model="isInGeorgia" :value="true">
                  Georgia
                </b-form-radio>
                <b-form-radio v-model="isInGeorgia" :value="false">
                  Abroad
                </b-form-radio>
              </b-form-group>
            </div>
            <div class="text-left row" v-if="isInGeorgia === false">
              <div class="col-12">Please indicate the exact location in the comment (Country, City).</div>
              <div class="col-12 col-lg-6">
                <b-form-group>
                  <multiselect
                    id="country col-lg-6"
                    v-model="country"
                    :options="countries"
                    track-by="countryID"
                    label="country"
                    placeholder="Choose country.."
                  ></multiselect>
                </b-form-group>
              </div>
              <div class="col-12 col-lg-6">
                <b-form-group>
                  <b-form-input v-model="city" placeholder="Enter city.."></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="text-left mt-4">
              <b-form-group label="3) Please upload the scanned version of your valid passport.">
                <b-form-file
                  class="col-12 col-md-6"
                  v-model="passportFile"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                ></b-form-file>
              </b-form-group>
            </div>
         </div>

        </div>
      </div>
      <div class="row">
        <div class="col mt-0 pt-3 mt-sm-5 pt-sm-5 mb-3">
          <button type="button" class="btn btn-light-bold" @click="onSubmitClick">Submit</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
export default {
  name: 'upload-docs-modal',

  components: { Multiselect },

  data() {
    return {
      isOpen: true,
      hasGeorgianResidencyCard: '',
      isInGeorgia: '',
      passportFile: null,
      IDCardFile: null,
      comment: '',
      country: '',
      city: '',
      countries: []
    }
  },

  mounted() {
    this.loadCountries();
  },

  methods: {
    loadCountries() {
      axios.get('/api/countries')
      .then(response => {
        this.countries = response.data.data;
      })
    },

    showModal() {
      this.$bvModal.show('upload-docs-modal');
    },

    onSubmitClick() {
      const formData = new FormData();

      let errors = [];
      if(this.hasGeorgianResidencyCard && !this.IDCardFile){
        errors.push('Please upload the scanned version of your Georgian residency card.');
      }
      if(!this.hasGeorgianResidencyCard && !this.comment){
        errors.push('Please fill the reason comment field');
      }
      if(!this.isInGeorgia && (!this.country || !this.city)){
        errors.push('Please indicate the exact location (Country, City).');
      }
      if(!this.passportFile) {
        errors.push('Please upload the scanned version of your valid passport.');
      }

      if(errors.length > 0) {
        this.helpers.notifyErrorMessage(errors.join('<br/>'));
        return;
      }

      formData.append('has_id', this.hasGeorgianResidencyCard === true ? '1' : '0');
      formData.append('is_in_georgia', this.isInGeorgia === true ? '1' : '0');
      formData.append('passport', this.passportFile);
      if(!this.hasGeorgianResidencyCard && this.comment) formData.append('comment', this.comment);
      if(this.hasGeorgianResidencyCard && this.IDCardFile) formData.append('id_card', this.IDCardFile);
      if(!this.isInGeorgia && this.country) formData.append('country_id', this.country.countryID);
      if(!this.isInGeorgia && this.city) formData.append('city', this.city);

      axios.post('/api/upload-docs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        window.location.reload();
      })
      .catch(error => {
        this.helpers.notifyErrorMessage(error)
      })
    }
  }
};
</script>

<style>
  @import "~vue-multiselect/dist/vue-multiselect.min.css";
  .font-16 {
    font-size: 16px !important;
  }

</style>
